<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.accountNumber"
            placeholder="推荐用户上级邮箱(查多级)"
            clearable
        >
        </el-input>


      </el-form-item>

      <el-form-item>

        <el-select v-model="searchForm.remarks"    placeholder="请选择交易类型" >
          <el-option
              v-for="item in options4"
              :key="item.value"
              :label="item.label"
              :value="item.value"

          >
          </el-option>
        </el-select>

        &nbsp; <el-form-item>

        <el-date-picker
            v-model="value2"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="注册开始日期"
            end-placeholder="注册结束日期"
            :default-time="['00:00:00','23:59:59']"
            align="right"
            @change="dateFormat">
        </el-date-picker>

      </el-form-item>


      </el-form-item>


      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>
    </el-form>


    <el-form :inline="true">
      <el-collapse v-model="activeNames" @change="handleChange">

        <div>

          <el-collapse v-model="activeNames" @change="handleChange">

            <el-descriptions  title="代理佣金累计" :column="4" :size="size">

              <el-descriptions-item label="佣金记录共">{{this.tableDataT[0].usEmail}}条</el-descriptions-item>
              <el-descriptions-item label="佣金">{{this.tableDataT[0].cardStatus}}USD</el-descriptions-item>
<!--              <el-descriptions-item label="累计简介">{{this.tableDataT[0].amountT}} </el-descriptions-item>
              <el-descriptions-item label="累计简介">{{this.tableDataT[0].amountT}} </el-descriptions-item>-->

            </el-descriptions>

          </el-collapse>

        </div>

      </el-collapse>

    </el-form>
    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>


      <el-table-column
          prop="id"
          label="id"
          width="70">
      </el-table-column>


      <!---->
      <el-table-column
          prop="spare1"
          label="推荐用户"
          width="230">
      </el-table-column>

      <el-table-column
          prop="cashAddress"
          label="推荐用户上级 "
          width="230">
      </el-table-column>


      <el-table-column
          prop="amount"
          label="手续费(USD)"
          width="130">
      </el-table-column>

      <el-table-column
          prop="afterAmount"
          label="佣金(USD)"
          width="135">
      </el-table-column>

      <!---->
      <el-table-column
          prop="details"
          label="佣金详情(USD)"
          width="300">
      </el-table-column>
      <el-table-column
          prop="remarks"
          label="交易类型"
          width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.remarks === '1'" type="success">卡片充值</el-tag>
          <el-tag size="small" v-else-if="scope.row.remarks === '2'" >申请开卡</el-tag>

        </template>
      </el-table-column>

      <el-table-column
          prop="type"
          label="记录类型"
          width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === '1'" type="success">Usdt充提记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '2'" type="success">Usd兑换记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '3'" type="success">卡片充提记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '4'" type="success">佣金记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '5'" type="success">CNY充提记录</el-tag>
          <el-tag size="small" v-else-if="scope.row.type === '6'" type="success">内部转账</el-tag>

        </template>
      </el-table-column>


      <el-table-column
          prop="state"
          label="交易状态"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.state === 'CONFIRMED'" type="success">已确认</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === 'UNCONFIRMED'" type="danger">未确认</el-tag>
          <el-tag size="small" v-else-if="scope.row.state === 'CONFIRMING'" type="danger">交易中</el-tag>

        </template>
      </el-table-column>


      <el-table-column
          prop="creationTime"
          label="佣金到账时间"
          width="165">
      </el-table-column>

    </el-table>


    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    <!--新增对话框-->
    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="600px"
        :before-close="handleClose">

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
        <el-form-item label="先查看今天数据是否入库" prop="accountNumber" >

        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>




    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      options: [ {
        value: '',
        label: ''
      }, {
        value: '1',
        label: 'Usdt充提记录'
      }, {
        value: '2',
        label: 'Usd兑换记录'
      }, {
        value: '3',
        label: '卡片充提记录'

      },{
        value: '4',
        label: '佣金记录'
      },{
        value: '5',
        label: 'CNY充提记录'
      },{
        value: '6',
        label: '内部转账'
      }]
      ,
      options2: [ {
        value: '',
        label: ''
      }, {
        value: 'In',
        label: '充值'
      }, {
        value: '0ut',
        label: '提现'
      }],
      options3: [ {
        value: '',
        label: ''
      }, {
        value: 'CONFIRMED',
        label: '已确认'
      }, {
        value: 'UNCONFIRMED',
        label: '未确认'
      }, {
        value: 'CONFIRMING',
        label: '交易中'
      }],
      options4: [ {
        value: '',
        label: ''
      }, {
        value: '2',
        label: '申请开卡'
      }, {
        value: '1',
        label: '卡片充值'
      }],

      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,

      dialogVisible: false,
      editForm: {

      },

      tableData: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一天',
          onClick(picker) {
            const end =new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));
            const start = new Date(new Date(new Date().getTime()).setHours(0, 0, 0, 0));
            picker.$emit('pick', [start, end]);

          }
        }, {
          text: '昨天',
          onClick(picker) {
            const start=new Date(new Date(new Date().getTime()-24*60*60*1000).setHours(0, 0, 0, 0));
            const end=new Date(new Date(new Date().getTime()-24*60*60*1000).setHours(23, 59, 59, 999));
            picker.$emit('pick', [start, end]);
          }
        }
          ,{
            text: '最近一周',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();

              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date(new Date(new Date().getTime()).setHours(23, 59, 59, 999));//new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);

            }
          }]
      },
      value2: '',
      editFormRules: {
       /* accountNumber: [
          {required: true, message: '请输入iosId', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        secretProtection: [
          {required: true, message: '请输入密保', trigger: 'blur'}
        ]*/
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      activeNames: ['2'],
      tableDataT: [],
      roleTreeData:  [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {



    this.getUserList()

    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })


  },
  methods: {
    dateFormat(picker) {
      /* 法一 */
      //console.log("11"+picker.length)
      //console.log(picker[0].toString())
      //console.log(picker[1].toString())
      this.searchForm.creationTime= picker[0].toString()+","+picker[1].toString();
      /* this.params.startTime = picker[0].toString()
       this.params.endTime = picker[1].toString()*/
      /* 法二 */
      // let startDate = this.params.date.toString()
      // this.params.startTime = startDate.substring(0, 10)
      // this.params.endTime = startDate.substring(11)
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {

      if(this.value2==null || this.value2==''){
        console.log("112"+this.value2)
        this.searchForm.creationTime='';
      }

      this.$axios.get("/card/agency/listJ", {
        params: {
          username: this.searchForm.accountNumber,
          type: this.searchForm.type,
          inIcon:this.searchForm.inIcon,
          state:this.searchForm.state,
          creationTime: this.searchForm.creationTime,
          remarks: this.searchForm.remarks,

          current: this.current,

          size: this.size

        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total

        this.tableDataT=JSON.parse(res.data.data.countId)
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/shares/lhasa/' + (this.editForm.id?'update' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/sale/account/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id,state,type) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
     // alert(state);

      if(type == '3'){
        if(state == 'UNCONFIRMED'){
          this.$axios.post("/card/record/update", ids).then(res => {
            this.$message({
              showClose: true,
              message: '恭喜你，操作成功',
              type: 'success',
              onClose:() => {
                this.getUserList()
              }
            });
          })
        }else{
          this.$message({
            showClose: true,
            message: '数据为已确认状态，不可再次确认。',
            type: 'danger',
            onClose:() => {
              this.getUserList()
            }
          });
        }
      }else{
        this.$message({
          showClose: true,
          message: '只有充值银行卡类型才能操作。',
          type: 'danger',
          onClose:() => {
            this.getUserList()
          }
        });
      }

    },

    roleHandle (id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/userWQKKDUXHAL/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>